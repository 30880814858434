<template>
  <b-overlay :show="show" rounded="sm" class="w-100">
    <div class="questionnaire-container py-3">
      <div class="questionBox py-3">
        <b-card
          class="card-custom-90"
          body-tag="body"
        >
            <div class="text-center" v-if="score['Questionnaire Score'] != undefined">
                <h2>Hi {{ name }}, based on your responses to {{ questionnaireTitle }} mental health check,</h2>
                <p class="font-size-20" v-if="this.questionnaireCategory == 'My mental health and how I feel'">
                  your Feeling index score is {{ score["Questionnaire Score"].section_score }}
                </p>
                <p class="font-size-20" v-else-if="this.questionnaireCategory == 'My mental wellbeing'">
                  your Wellbeing index score is {{ score["Questionnaire Score"].section_score }}
                </p>
                <p class="font-size-20" v-else>
                  your Mental Health Trait Score is {{ score["Questionnaire Score"].section_score }}
                </p>
                <hr class="mhc-divider m-auto" />
                
                <h1 class="pt-1">
                  {{ score['Questionnaire Score'].section_score }}
                </h1>
                <p>out of 10</p>
                <hr class="mhc-divider m-auto" />
                
                <h3 class="pt-1" v-if="this.questionnaireCategory == 'My mental health and my life situation(s)'">Contributing factors</h3>
                <what-does-this-mean v-if="this.questionnaireCategory == 'My mental health and my life situation(s)'" type="home-circle-chart-bottom" />

                <div class="m-auto" v-if="showBarData">
                  <bar-chart :barData="barChart" :barColors="colors" :barCategories="barCategories"/>
                </div>

                <div class="mt-1">
                  <div class="text-center mb-1" v-if="professional != null">
                    <img :src="professional.profile_image_url" class="rounded-image-100px rounded-circle" v-if="professional.profile_image_url" />
                    <p class="mb-0 mt-05">{{professional.first_name}} {{professional.last_name}}</p>
                    <small class="text-gray">{{professional.qualifications}}</small>
                  </div>
                  
                  <div class="triangle" v-if="professional != null"></div>
                  <div class="professional-cloud-opinion" v-if="questionnaireCategory == 'My mental health and my life situation(s)'">
                    <h3 class="pt-1">What does your score mean?</h3>
                    <p class="pt-1" v-for="(msg, index) in score['Questionnaire Score'].messages" :key="index" v-html="msg"></p>
                    
                    <h3 class="pt-1" v-if="score.sections['Section A'].messages.length > 0">Additional information</h3>
                    <p class="pt-1" v-for="(msg, index) in score.sections['Section A'].messages" :key="(index+1)*56" v-html="msg"></p>
                  </div>
                  
                  <div class="professional-cloud-opinion" v-else>
                    <h3 class="pt-1">What does your score mean?</h3>
                    <p class="pt-1" v-for="(msg, index) in score['Questionnaire Score'].messages" :key="index" v-html="msg"></p>
                  </div>
                </div>
                
            </div>

            <b-modal id="seek-support-modal" ref="seek-support-modal" size="lg" hide-footer title="Seek support" centered>
              <div id="support-modal">
                <h3>Hi {{ userData.username }}</h3>
                <p>
                  You have received a very low score for this mental health check and we would recommend seeking more immediate support. 
                </p>

                <h3>
                  Crisis support and helplines
                </h3>

                <div class="my-2">
                  <h4>• Emergency Services</h4>
                  <p>
                    If you, or someone you know is in a mental health crisis and need medical help fast:<br>
                  - Ring 999 to contact emergency services<br>
                  - Go to your nearest Accident and Emergency (A &amp; E) department
                  </p>
                  <p>
                    If it is not a medical emergency, but you still need urgent help:<br>
                  - Ring 111 for professional health advice 24/7 and 365 days a year and guidance on where to access appropriate health services.
                  </p>
                </div>
                
                <div class="my-2">
                  <h4>• YoungMinds</h4>
                  <p>YoungMinds is focused on offering support and help for young people experiencing mental health difficulties but it does have resources for adults as well. </p>
                  <p>
                    <strong>
                      Website
                    </strong>
                    <a href="https://youngminds.org.uk" target="_blank">
                      <u>https://youngminds.org.uk</u>
                    </a>
                  </p>
                  <p>Parents: Mon-Fri 9.30am – 4pm. Telephone Number - 0808 802 5544</p>
                  <p>Young person: FREE 24/7 Support across the UK. Text ‘YM’ to 85258</p>
                </div>

                <div class="my-2">
                  <h4>• Anxiety UK</h4>
                  <p>Anxiety UK is focused on tackling anxiety disorders and other similar related mental health conditions</p>
                  <p>
                    <strong>
                      Website
                    </strong>
                    <a href="https://www.anxietyuk.org.uk" target="_blank">
                      <u>https://www.anxietyuk.org.uk</u>
                    </a>
                  </p>
                  <p>Telephone Number – 03444 775 774</p>
                  <p>Text – 07537 416 905</p>
                </div>

                <div class="my-2">
                  <h4>• Campaign Against Living Miserably (CALM)</h4>
                  <p>CALM supports people who are experiencing mental health difficulties and offers numerous different support options including a helpline and online chat support.</p>
                  <p>
                    <strong>
                      Website
                    </strong>
                    <a href="https://www.thecalmzone.net" target="_blank">
                      <u>https://www.thecalmzone.net/</u>
                    </a>
                  </p>
                  <p>Telephone Number – 0800 58 58 58</p>
                </div>

                <div class="my-2">
                  <h4>• Sane</h4>
                  <p>Sane works to promote support and help for people who suffer from mental illnesses.</p>
                  <p>
                    <strong>
                      Website
                    </strong>
                    <a href="www.sane.org.uk" target="_blank">
                      <u>www.sane.org.uk</u>
                    </a>
                  </p>
                  <p>Telephone Number – 07984 967 708</p>
                  <p>Email – support@sane.org.uk</p>
                </div>

                <div class="my-2">
                  <h4>• Samaritans</h4>
                  <p>Samaritans is another well-known charity in the UK, and they can provide a lot of help and support when it comes to dealing with mental health issues.</p>
                  <p>
                    <strong>
                      Website
                    </strong>
                    <a href="https://www.samaritans.org" target="_blank">
                      <u>https://www.samaritans.org/</u>
                    </a>
                  </p>
                  <p>Telephone Number 116123 (for free 24/7 support)</p>
                </div>

                <div class="my-2">
                  <h4>• Mind</h4>
                  <p>Mind offers support and help for people who are experiencing mental health difficulties. They are dedicated to preventing male suicide.</p>
                  <p>
                    <strong>
                      Website
                    </strong>
                    <a href="https://mind.org.uk" target="_blank">
                      <u>https://mind.org.uk</u>
                    </a>
                  </p>
                  <p>Telephone Number – 0300 123 3393</p>
                  <p>Text - 86463</p>
                </div>
              </div>
            </b-modal>

            <div class="w-400">
              <b-button block variant="primary" size="lg" :to="{ name: 'my-hub' }" class="mt-3">CONTINUE</b-button>

              <div class="mt-2 text-center">
                <h3 class="mhc-link-underline" @click="goToForm">Start another Mental Health Check</h3>
              </div>
            </div>

        </b-card>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import EventBus from "../../main.js"
import store from "@/store/index";
import { BOverlay, BRow, BCard, BButton } from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import WhatDoesThisMean from '../../components/modals/WhatDoesThisMean.vue'
import BarChart from '../../components/my-hub/BarChart.vue'
import * as utility from "../../util";

export default {
  components: {
    BOverlay,
    BRow,
    BCard,
    BButton,
    WhatDoesThisMean,
    BarChart,
  },
  data() {
    return {
      userData: [],
      showBarData: false,
      questionnaireResult: [],
      questionnaireTitle: "",
      questionnaireCategory: "",
      score: [],
      name: "",
      colors: ["#59949c"],
      barChart: [],
      professional: null,
      userData: JSON.parse(localStorage.getItem('userData'))
    };
  },
  computed: {
    show() {
      return store.state.formStoreModule.show;
    },
    currentQuestionnaire() {
      return this.$store.getters["formStoreModule/currentQuestionnaire"]
    }
  },
  methods: {
    addFollowings() {
      let token_val = "";
      if(store.state.formStoreModule.userInvitedToken != null) {
        token_val = store.state.formStoreModule.userInvitedToken;
      } else {
        token_val = localStorage.getItem("invite_token");
      }

      this.$http.post("/api/auth/user/add-followings", {//test
        "token": token_val        
      })
      .then((res) => {
        localStorage.removeItem("invite_token");
        store.commit("formStoreModule/setUserInvitedToken", null);
      }).catch((err) => {   
        console.log(err)
             
        localStorage.removeItem("invite_token");
        store.commit("formStoreModule/setUserInvitedToken", null);

        this.$toast({
          component: ToastificationContent,
          props: {
              title: "Something went wrong. Please try again",
              icon: "AlertCircleIcon",
              variant: "danger",
          },
        });
      });
    },
    goToForm() {
      store.dispatch('formStoreModule/resetFormParams')
      this.$router.push({ path: 'form' })
    }
  },
  mounted() {

    // check if the user has set "invitedToken"
    // if he has, that means that he was invited by another user
    // and now since he has completed a questionnaire and is registered
    // we should make him follow and the user that invited him follow each other
    if(store.state.formStoreModule.userInvitedToken != null || localStorage.getItem("invite_token") != null) {
      this.addFollowings();
    }

    var elmnt = document.getElementsByClassName("questionnaire-placeholder")[0];
    elmnt.scrollIntoView();

    try {
    
      store.commit("formStoreModule/setShow", true);

      // Get data from localStorage
      this.questionnaireResult = JSON.parse(localStorage.getItem('questionnaireResult'))

      // If you try jump to Score page
      if(this.questionnaireResult == null) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Something went wrong. Please try again",
            icon: "AlertCircleIcon",
            variant: "danger",
          },
        });

        this.$router.push({ name: 'form' })
      }

      // Add reminder to object
      this.questionnaireResult.reminder = localStorage.getItem('reminder')

      this.questionnaireResult.questionnaire_started_code = localStorage.getItem("random_string")

      const userData = store.state.app.userData;

      this.name = userData.name;

      // IMPORTANT
      // go through the results to ensure that the API gets only answers in the following format:
      /*
        {
          "questionnaire": 6,
          "questions": [{
            "question": 1,
            "answers": [1] // FOR QUESTIONS THAT HAVE MULTIPLE ANSWERS, INSTEAD OF ARRAY OF ID OF ANSWERS WE HAVE THE WHOLE ANSWER OBJECT (this was done on purpose so that the BACK button would work)
                          // That is why we need to convert these answer objects to just array of answer ids like it is done for single answer questions
          },
          .
          .
          .
      */
      // Go through the questions array in the results
      for(let i = 0; i < this.questionnaireResult.questions.length; i++) {

        if(this.questionnaireResult.questions[i].answers.length > 0 && this.questionnaireResult.questions[i].answers[0] != null) {
          // If first answer for a question has the "id" key, that means that it's a multi answer question and we need to deal with it
          if(this.questionnaireResult.questions[i].answers[0].id != undefined) {

            // Create a local empty array
            let arr = [];

            // Loop over all the answers for the given multi answer question
            for(let j = 0; j < this.questionnaireResult.questions[i].answers.length; j++) {

              // Add the answer id to the arr array
              arr.push(this.questionnaireResult.questions[i].answers[j].id)
            }

            // Replace the current question answers array of objects with just the object ids :) 
            this.questionnaireResult.questions[i].answers = arr;
          }
        } else {
          this.questionnaireResult.questions[i].answers = [];
        }
      }
      
      this.$http.post("/api/auth/questionnaire/result", this.questionnaireResult)
        .then((res) => {
          this.$toast({
              component: ToastificationContent,
              props: {
                title: "Form Submitted",
                icon: "EditIcon",
                variant: "success",
              },
          });

          // Remove the questionnaire given answers data and the questionnaire slug, reminder and questionnaire_completed flag
          localStorage.removeItem('questionnaireResult');
          localStorage.removeItem('questionnaireSlug');
          localStorage.removeItem('reminder');
          localStorage.removeItem('questionnaire_completed');
          localStorage.removeItem('random_string');

          let user_questionnaire = res.data.user_questionnaire
          this.questionnaireCategory = res.data.questionnaire_category
          console.log(this.questionnaireCategory)
          if(res.data.is_score_low == 1) {
            this.$refs["seek-support-modal"].show();
          }

          this.$http.get("/api/auth/questionnaire/score/" + user_questionnaire)
            .then((res) => {
              if(res != undefined) {
                this.score = res.data

                if(this.questionnaireCategory == "My mental health and my life situation(s)") {        
                  let biological = res.data.sections['Section B - Biological question']
                  let psychological = res.data.sections['Section B - Psychological question']
                  let social = res.data.sections['Section B - Social question']
                  this.barChart = [biological.section_score, psychological.section_score, social.section_score]
                  this.colors = [biological.section_score_color, psychological.section_score_color, social.section_score_color]
                  this.barCategories = ["Biological", "Psychological", "Social"]
                  this.showBarData = true;
                } else if(this.questionnaireCategory == "My mental health and my job/career") {
                  let before_work = res.data.sections['Before Work']
                  let at_work = res.data.sections['At Work']
                  let after_work = res.data.sections['After Work']
                  this.barChart = [before_work.section_score, at_work.section_score, after_work.section_score]
                  this.colors = [before_work.section_score_color, at_work.section_score_color, after_work.section_score_color]
                  this.barCategories = ["Before Work", "At Work", "After Work"]
                  this.showBarData = true;

                } else if(this.questionnaireCategory == "My mental wellbeing") {
                  let calm = res.data.sections['Calm & Inner peace']
                  let joy = res.data.sections['Joy and Contentment']
                  let adaptability = res.data.sections['Adaptability']
                  let rest = res.data.sections['Rest & Relaxation']
                  let health = res.data.sections['Health & Lifestyle']
                  let relationships = res.data.sections['Relationships & Connections']
                  let satisfaction = res.data.sections['Satisfaction & Fulfillment']
                  
                  this.barChart = [calm.section_score, joy.section_score, adaptability.section_score, rest.section_score, health.section_score, relationships.section_score, satisfaction.section_score]
                  this.colors = [calm.section_score_color, joy.section_score_color, adaptability.section_score_color, rest.section_score_color, health.section_score_color, relationships.section_score_color, satisfaction.section_score_color]
                  this.barCategories = ['Calm & Inner peace','Joy and Contentment', 'Adaptability', 'Rest & Relaxation', 'Health & Lifestyle', 'Relationships & Connections', 'Satisfaction & Fulfillment']              
                  this.showBarData = true;
                }

                this.questionnaireTitle = res.data.questionnaire_title

                this.professional = res.data.professional

                store.commit('formStoreModule/setShow', false)
              }
            })
            .catch((error) => {
              console.log(error)
              
              this.$toast({
                component: ToastificationContent,
                props: {
                    title: "Something went wrong. Please try again",
                    icon: "AlertCircleIcon",
                    variant: "danger",
                },
              });
            });
          
        })
        .catch((error) => {
          // if the user has not subscribed to the paid plan and has done 3 questionnaires
          console.log(error)
          if(error.response.status == 422) {
              // Show modal
            EventBus.$emit('showPricing', true)
          } else {
            console.log(error);

            if(error.response && error.response.status == 400) {
              let daysLeft = 7 - error.response.data.days_since_last_result
              let dayText = (daysLeft > 1) ? "days" : "day"

              let message = `We recommend having a seven day rest period before taking this mental health check again. You will be able to take this "${localStorage.getItem('questionnaireTitle')}" mental health check in ${utility.inWords(daysLeft)} ${dayText} time.`;
              let timeout = 10000

              if(error.response.data.questionnaire_type == "Emotions") {
                message = `We recommend having at least one day rest period before taking this mental health check again. You will be able to take this "${error.response.data.title}" mental health check tomorrow`;
              }

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: "AlertCircleIcon",
                  variant: "info",
                },
              },
              {
                timeout
              })

              // Redirect to form
              this.$router.push({ name: 'form' })
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error.response.data.message,
                  icon: "AlertCircleIcon",
                  variant: "danger",
                },
              });
              
              store.commit('formStoreModule/setShow', false)
            }
          }
        });

    } catch(error) {
        if(error.response && error.response.status == 400) {
          let daysLeft = 7 - error.response.data.days_since_last_result
          let dayText = (daysLeft > 1) ? "days" : "day"

          let message = `We recommend having a seven day rest period before taking this mental health check again. You will be able to take this "${localStorage.getItem('questionnaireTitle')}" mental health check in ${utility.inWords(daysLeft)} ${dayText} time.`;
          let timeout = 10000

          this.$toast({
            component: ToastificationContent,
            props: {
              title: message,
              icon: "AlertCircleIcon",
              variant: "info",
            },
          },
          {
            timeout
          })

          // Redirect to form
          this.$router.push({ name: 'form' })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
                title: "Something went wrong while processing results. Please try again",
                icon: "AlertCircleIcon",
                variant: "danger",
            },
          });

          // Redirect to form
          this.$router.push("/")
        }
      }

    }
};
</script>
<style scoped>
@import "../../assets/css/modals-style.css";
#support-modal > div > p {
  font-weight: normal !important;
}

#support-modal > div > h4 {
  font-weight: bold;
}

</style>
